import React from "react";
import DeckGL from "@deck.gl/react";
import { Map as StaticMap } from "react-map-gl";
import { IconLayer } from "deck.gl";
import { PolygonLayer } from "@deck.gl/layers";
import AnimatedArcLayer from "../map/ArcBrushingLayer";
import uav from "../icons/uav.svg";

const Map = (alerts) => {
  const MAP_TOKEN =
    "pk.eyJ1IjoicnRwcjEyIiwiYSI6ImNrb3BqaGRtZjBscXkyeGw0Z3N3dzcybHgifQ.SqX6UAKLPp8VcGg0OXgnKg";

  const INITIAL_VIEW_STATE = {
    latitude: 31.5901,
    longitude: 34.9076,
    zoom: 9,
  };

  const layers = [
    new PolygonLayer({
      id: "polygon-layer",
      data: alerts.alerts.polygons,
      pickable: false,
      stroked: true,
      filled: true,
      wireframe: false,
      lineWidthMinPixels: 1,
      getPolygon: (d) => d.coordinates,
      getFillColor: [224, 63, 78, 115],
      getLineColor: [224, 63, 78, 88],
      getLineWidth: 1,
    }),
    new AnimatedArcLayer({
      id: "layer",
      data: alerts.alerts.rockets,
      getWidth: 3,
      strokeWidth: 2,
      getSourceColor: [255, 251, 152],
      getTargetColor: [187, 0, 0],
      getFrequency: 0.6,
      animationSpeed: 0.3,
      tailLength: 2.1,
    }),
    new IconLayer({
      id: "icon-layer",
      data: alerts.alerts.uav,
      getIcon: (d) => ({
        url: uav,
        width: 128,
        height: 128,
        anchorY: 175,
      }),
      getSize: (d) => 5,
      pickable: true,
      sizeScale: 15,
      getPosition: (d) => d.coordinates,
    }),
  ];

  return (
    <div style={{ overflow: "hidden" }}>
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
      >
        <StaticMap
          mapboxAccessToken={MAP_TOKEN}
          mapStyle={"mapbox://styles/rtpr12/ck8ztaphr0pxb1jo01f471wg7"}
        />
      </DeckGL>
    </div>
  );
};
export default Map;
