import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Map from "../../components/Map";

const Simulation = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState({
    alerts: [
      [
        {
          category: 1,
          location: null,
          timestamp: null,
          coordinates: null,
          polygons: null,
          name: null,
        },
      ],
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "/api/dashboard/alert?date=" + searchParams.get("date")
        );
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchParams]);

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="100px"
        gap="20px"
      >
        {/* {"Map"} */}
        <Box
          gridColumn="span 10"
          gridRow="span 7"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          overflow={"hidden"}
        >
          <Map alerts={data} />
        </Box>
        {/* {"Alerts"} */}
        <Box
          gridColumn="span 2"
          gridRow="span 7"
          backgroundColor={colors.primary[400]}
          display="flex"
          overflow={"auto"}
        >
          <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
          >
            {data.alerts.map((date, d_index) => (
              <TreeItem
                nodeId={d_index + "_"}
                label={date[0].timestamp}
                onClick={() => {
                  let polygons = [];
                  let rockets = [];
                  let uav = [];
                  date.forEach((alert) => {
                    if (alert.polygons) polygons.push(alert.polygons);
                    if (alert.coordinates) {
                      if (alert.category === 1) rockets.push(alert.coordinates);
                      else if (alert.category === 2)
                        uav.push({
                          coordinates: alert.coordinates.targetPosition,
                        });
                    }
                  });
                  setData({
                    ...data,
                    polygons: polygons,
                    rockets: rockets,
                    uav: uav,
                  });
                }}
              >
                {date.map((alert, index) => (
                  <TreeItem
                    nodeId={alert.name + "_" + index}
                    label={alert.name}
                    onClick={() => {
                      let polygons = [];
                      let rockets = [];
                      let uav = [];
                      if (alert.polygons) polygons.push(alert.polygons);
                      if (alert.coordinates) {
                        if (alert.category === 1)
                          rockets.push(alert.coordinates);
                        else if (alert.category === 2)
                          uav.push({
                            coordinates: alert.coordinates.targetPosition,
                          });
                      }
                      setData({
                        ...data,
                        polygons: polygons,
                        rockets: rockets,
                        uav: uav,
                      });
                    }}
                  />
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </Box>
      </Box>
    </Box>
  );
};
export default Simulation;
