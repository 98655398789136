import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import War from "./scenes/war";
import Simulation from "./scenes/simulation";
import Live from "./scenes/live";
import InfoAlert from "./components/InfoAlert";

function App() {
  const [theme, colorMode] = useMode();

  const isLiveRoute = window.location.pathname === "/live";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <InfoAlert />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {!isLiveRoute && <Topbar />}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/war/:name" element={<War />} />
              <Route path="/simulation" element={<Simulation />} />
              <Route path="/live" element={<Live />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
