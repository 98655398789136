import { LineChart } from "@mui/x-charts/LineChart";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const Chart = (data) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <LineChart
      series={[
        {
          data: data.data[1],
          label: "Number of alerts",
          color: colors.redAccent[500],
        },
      ]}
      xAxis={[{ scaleType: "point", data: data.data[0] }]}
    />
  );
};
export default Chart;
