import * as React from "react";
import Cookies from "universal-cookie";
import { Alert, AlertTitle, Box, Collapse } from "@mui/material";

const InfoAlert = () => {
  const cookies = new Cookies();
  const [open, setOpen] = React.useState(cookies.get("Info") ? false : true);
  return (
    <div style={{ zIndex: "100", position: "fixed", margin: "25px" }}>
      <Box sn={{ "% > legend": { mt: 2 } }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            onClose={() => {
              setOpen(false);
              cookies.set("Info", true, { path: "/" });
            }}
          >
            <AlertTitle>
              <b>Info</b>
            </AlertTitle>
            <b>It is important to note:</b> The information is based on alerts
            from{" "}
            <a href="https://www.oref.org.il/en">Israeli Home Front Command</a>{" "}
            only. The information whether the rocket/UAV hit the target or was
            intercepted in the air is not made public. The exit and impact
            points are random within defined polygons of the alert areas.
            <br />
            <br />
            <b>
              Do not use it if human life is at stake. Do not assume it shows
              your correct data. Do not assume it works properly, or even works
              at all. Always follow official guidelines and procedures published
              by{" "}
              <a href="https://www.oref.org.il/en">
                Israeli Home Front Command
              </a>
            </b>
          </Alert>
        </Collapse>
      </Box>
    </div>
  );
};
export default InfoAlert;
