import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import { useEffect, useState } from "react";
import Chart from "../../components/Chart";
import PieChart from "../../components/PieChart";
import HorizontalChart from "../../components/HorizontalChart";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState({
    wars: [],
    top_10: [
      {
        Areas: null,
        count: null,
      },
    ],
    rockets: 0,
    uavs: 0,
    terror: 0,
    last_week: [[], []],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/dashboard/");
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const interval = setInterval(fetchData, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // const data = {
  //   wars: [
  //     {
  //       name: "Swords of Iron",
  //       start_date: "2023-10-07",
  //       end_date: null,
  //       alerts_count: 8108,
  //     },
  //     {
  //       name: "Shield and Arrow",
  //       start_date: "2023-05-09",
  //       end_date: "2023-05-13",
  //       alerts_count: 777,
  //     },
  //     {
  //       name: "Breaking Dawn",
  //       start_date: "2022-08-05",
  //       end_date: "2022-08-07",
  //       alerts_count: 756,
  //     },
  //     {
  //       name: "Guardian of the Walls",
  //       start_date: "2021-05-10",
  //       end_date: "2021-05-21",
  //       alerts_count: 2000,
  //     },
  //     {
  //       name: "Black Belt",
  //       start_date: "2019-11-12",
  //       end_date: "2019-11-14",
  //       alerts_count: 929,
  //     },
  //     {
  //       name: "Protective Edge",
  //       start_date: "2014-07-08",
  //       end_date: "2014-08-26",
  //       alerts_count: 1875,
  //     },
  //   ],
  //   top_10: [
  //     { Areas: "אזור תעשייה הדרומי אשקלון", count: 348 },
  //     { Areas: "נתיב העשרה", count: 332 },
  //     { Areas: "נחל עוז", count: 314 },
  //     { Areas: "עוטף עזה 224", count: 299 },
  //     { Areas: "שדרות, איבים, ניר עם", count: 257 },
  //     { Areas: "כיסופים", count: 250 },
  //     { Areas: "זיקים", count: 249 },
  //     { Areas: "עוטף עזה 218", count: 241 },
  //     { Areas: "מטווח ניר עם", count: 229 },
  //     { Areas: "אשקלון - דרום", count: 197 },
  //   ],
  //   rockets: 17283,
  //   uavs: 428,
  //   terror: 53,
  //   last_week: [
  //     ["2023-12-15", "2023-12-16", "2023-12-17", "2023-12-18"],
  //     [44, 35, 11, 24],
  //   ],
  // };

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="100px"
        gap="20px"
      >
        {/* ROW 1 */}

        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox title={data.rockets} subtitle="Rockets" />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox title={data.uavs} subtitle="UAVs" />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox title={data.terror} subtitle="Terrorist Infiltration" />
        </Box>
        {/* ROW 2 */}
        {data.wars.map((war, index) => (
          <Box
            key={index}
            gridColumn="span 2"
            gridRow="span 1"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              window.location.href = "/war/" + war.name;
            }}
          >
            <StatBox title={war.alerts_count} subtitle={war.name} />
          </Box>
        ))}

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          {"Alerts per War"}

          <PieChart alerts={data} />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Chart data={data.last_week} />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <HorizontalChart top_10={data.top_10} />
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
