import { useState, useCallback } from "react";
import { IconButton, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Date = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const [logsFromOnAccept, setLogsFromOnAccept] = useState([]);

  const onAccept = useCallback((newValue) => {
    setLogsFromOnAccept((prev) => [newValue, ...prev]);
    const date =
      newValue.year() + "-" + (newValue.month() + 1) + "-" + newValue.date();
    openSimulationDate(date);
  }, []);

  const openSimulationDate = (date) => {
    window.location.href = "/simulation/?date=" + date;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ background: colors.primary[400] }}>
        <IconButton type="button" sx={{ p: 1 }} onClick={() => setOpen(true)}>
          <CalendarMonthIcon />
        </IconButton>
        <DatePicker
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              style: { display: "none" }, // hide the textfield
            },
          }}
          onAccept={onAccept}
        />
      </div>
    </LocalizationProvider>
  );
};
export default Date;
