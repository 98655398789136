import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const HorizontalChart = ({ top_10 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const chartSetting = {
    xAxis: [
      {
        label: "Cities",
      },
    ],
  };

  top_10.sort((a, b) => a.count - b.count);

  return (
    <BarChart
      dataset={top_10}
      yAxis={[{ scaleType: "band", dataKey: "Areas" }]}
      series={[
        {
          dataKey: "count",
          label: "Top 10 Cities with most alerts",
          color: colors.redAccent[500],
        },
      ]}
      layout="horizontal"
      {...chartSetting}
      margin={{ left: 150 }}
    />
  );
};
export default HorizontalChart;
