import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import HorizontalChart from "../../components/HorizontalChart";
import Chart from "../../components/Chart";

const War = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { name } = useParams();

  const [data, setData] = useState({
    war_dates: { start: null, end: null },
    name: "",
    rockets: 0,
    uavs: 0,
    terror: 0,
    top_10: [
      {
        Areas: null,
        count: null,
      },
    ],
    chart: [[], []],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/dashboard/war/" + name);
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [name]);

  const dates =
    data.war_dates.start +
    " - " +
    (data.war_dates.end === null ? "Present" : data.war_dates.end);

  return (
    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="100px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 12"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox title={data.name} subtitle={dates} />
        </Box>
        {/* ROW 2 */}

        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            window.location.href = "/alert/rockets";
          }}
        >
          <StatBox title={data.rockets} subtitle="Rockets" />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            window.location.href = "/alert/uavs";
          }}
        >
          <StatBox title={data.uavs} subtitle="UAVs" />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            window.location.href = "/alert/terrot";
          }}
        >
          <StatBox title={data.terror} subtitle="Terrorist Infiltration" />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          {" "}
          <HorizontalChart top_10={data.top_10} />
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Chart data={data.chart} />
        </Box>
      </Box>
    </Box>
  );
};
export default War;
