import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import Alert from "react-bootstrap/Alert";
import Map from "../../components/Map";
import uav_icon from "../../icons/uav.svg";
import terrorAtack_icon from "../../icons/terrorAtack.svg";
import alert_icon from "../../icons/alertWhite.svg";

const isLiveRoute = window.location.pathname === "/live";

// const socket = isLiveRoute
//   ? io.connect("127.0.0.1:8000", {
//       path: "/io/socket.io",
//     })
//   : null;
const socket = isLiveRoute ? io.connect("/") : null;

const Live = () => {
  const [data, setData] = useState({
    alerts: [],
    polygons: [],
    rockets: [],
    uav: [],
  });

  useEffect(() => {
    socket.on("alerts", (alertData) => {
      if (alertData === undefined || alertData.length === 0) setData({});

      if (Array.isArray(alertData.locations)) {
        let alerts_location = [];
        let cityLayer = [];
        let arch = [];
        let uav = [];

        alertData.locations.forEach((alert) => {
          alerts_location.push({
            type: alert.type,
            time: alert.time,
            name: alert.name,
          });
          if (alert.polygons) cityLayer.push(alert.polygons);
          if (alert.type === "rockets") arch.push(alert.coordinates);
          else if (alert.type === "uav")
            uav.push({ coordinates: alert.coordinates });
        });
        setData({
          alerts: alerts_location,
          polygons: cityLayer,
          rockets: arch,
          uav: uav,
        });
      }
    });

    return () => {
      socket.off("alert");
    };
  }, []);
  useEffect(() => {
    // Disable scroll
    document.body.style.overflow = "hidden";

    return () => {
      // Enable scroll
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div>
      <div
        style={{
          zIndex: "100",
          position: "fixed",
          margin: "25px",
        }}
      >
        {/* <InfoAlert></InfoAlert> */}
      </div>
      <div className="side">
        <div className="sidebar">Alerts: {data.alerts.length}</div>
        <div className="alerts">
          {data.alerts.map((location, index) => (
            <Alert variant="danger" key={index}>
              <img
                src={
                  location["type"] === "uav"
                    ? uav_icon
                    : location["type"] === "terroratack"
                    ? terrorAtack_icon
                    : alert_icon
                }
                height={"30px"}
                alt={"alert"}
              />{" "}
              &nbsp;
              <span style={{ fontWeight: "bold" }}>{location["name"]}</span>
              <br />
              <span>Time to Reach the Protected Space: </span>
              <span style={{ fontWeight: "bold" }}>{location["time"]}</span>
            </Alert>
          ))}
        </div>
      </div>

      <Map alerts={data} />
    </div>
  );
};
export default Live;
