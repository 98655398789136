import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import Date from "../../components/Date";
import alertWhite from "../../icons/alertWhite.svg";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" justifyContent="space-between" p={2} m={1}>
      <Box>
        <IconButton type="button" sx={{ p: 1 }} href="/">
          <HomeIcon />
        </IconButton>
        <IconButton type="button" sx={{ p: 1 }} href="/live" target="_blank">
          <img src={alertWhite} alt="alert" />
        </IconButton>
      </Box>
      <Box>{/* SEARCH BAR */}</Box>
      <Box>
        <Date />
      </Box>
    </Box>
  );
};
export default Topbar;
